import { React, useState, useEffect, lazy } from 'react'
import { Tabs, Tab } from 'react-bootstrap';
import { Dialog } from "@material-ui/core";
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from '../../../appStore/actionCreators/Tab/tab_actionCreators';
import { setQuotesTabType } from "../../../appStore/actionCreators/myQuotes";
import Header from './Header';
import { BookingProvider } from "./bookingContext";
import { TripType, starAllianceOption, oneWorldOption, skyTeam, SearchWidgetInput, allianceName } from "../../../utils/constant";
import { v4 as uuidv4 } from "uuid";
import {
  setIsErrorMultiCityTrip,
  setModifySearchValue,
  setSegmentArrayLength,
  setPNRRemarkCityFraudCheck,
  setCityFraudCheck,
  setCityFraudCheck1,
  setShowAgencyInfo
} from '../../../appStore/actionCreators/searchForm';

import { setTokenInitRequestData } from '../../../appStore/actionCreators/initToken';
import { getAllSearchResults } from '../../../appStore/thunks/getAllSearchResults';
import { setInitToken } from "../../../appStore/actionCreators/initToken";
import { env } from '../../../../src/appConfig';
import { useHistory } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import ToastMessages from '../../../utils/utility/ToastedMessage';
import getApiResponse from '../../../services/getApiResponse';
import { useTranslation } from 'react-i18next';
import i18n from '../../../LangConfig';
import { setLoadingData } from '../../../appStore/actionCreators/itinenary';

const BookingFor = lazy(() => import("./Bookingfor"));
const Trip = lazy(() => import("./Trip"));

function SearchFormContainerNew() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();
  const tabName = useSelector((state) => state.tab.tab_Name);
  const tripTypeValue = useSelector((state) => state.searchForm.tripType);
  const isCityFraud = useSelector((state) => state.searchForm.isCityFraud);
  const isCityFraud1 = useSelector((state) => state.searchForm.isCityFraud1);
  let [cityFraud, setCityFraud] = useState(isCityFraud ? true : false);
  let [cityFraud1, setCityFraud1] = useState(isCityFraud1 ? true : false);

  let tripType;
  if (tripTypeValue === "Return") {
    tripType = "RoundTrip"
  } else {
    tripType = tripTypeValue
  }

  const orgId = useSelector((state) => state.agentProfile.orgId);
  const agencyId = useSelector((state) => state.agentProfile.agencyId);
  const agentId = useSelector((state) => state.agentProfile.agentId);
  const agencyGroupId = useSelector((state) => state.agentProfile.agencyGroupId);
  const userId = useSelector((state) => state.agentProfile.userId);
  const agencyLocation = useSelector((state) => state.agentProfile.agencyLocation);
  const agencyName = useSelector((state) => state.agentProfile.agencyName);

  let agentName = useSelector((state) => state.agentProfile.agentName);
  agentName = agentName.replace(/ /g, '')

  let adultsCount = useSelector((state) => state.searchForm.passengerAdult);
  let childCount = useSelector((state) => state.searchForm.passengerChildren);
  let infantCount = useSelector((state) => state.searchForm.passengerInfant);

  const departureDate = useSelector((state) => state.searchForm.departDate);
  const returnDate = useSelector((state) => state.searchForm.returnDate);
  const openJawDate_B = useSelector((state) => state.searchForm.openJawDate_B);
  const flyingFrom = useSelector((state) => state.searchForm.flyingFrom);
  const flyingTo = useSelector((state) => state.searchForm.flyingTo);
  const openJawFlyFrom_B = useSelector((state) => state.searchForm.openJawFlyingFrom_B);
  const openJawFlyTo_B = useSelector((state) => state.searchForm.openJawFlyingTo_B);
  const cabin = useSelector((state) => state.searchForm.cabin);
  const atLeastFreeBaggage = useSelector((state) => state.searchForm.atLeastFreeBaggage);
  const altEval = {
    multiCityFlyFrom_0: useSelector((state) => state.searchForm.multiCityFlyingFrom_0),
    multiCityFlyTo_0: useSelector((state) => state.searchForm.multiCityFlyingTo_0),
    multiCityFlyFrom_1: useSelector((state) => state.searchForm.multiCityFlyingFrom_1),
    multiCityFlyTo_1: useSelector((state) => state.searchForm.multiCityFlyingTo_1),
    multiCityFlyFrom_2: useSelector((state) => state.searchForm.multiCityFlyingFrom_2),
    multiCityFlyTo_2: useSelector((state) => state.searchForm.multiCityFlyingTo_2),
    multiCityFlyFrom_3: useSelector((state) => state.searchForm.multiCityFlyingFrom_3),
    multiCityFlyTo_3: useSelector((state) => state.searchForm.multiCityFlyingTo_3),
    multiCityFlyFrom_4: useSelector((state) => state.searchForm.multiCityFlyingFrom_4),
    multiCityFlyTo_4: useSelector((state) => state.searchForm.multiCityFlyingTo_4),
    multiCityDate_0: useSelector((state) => state.searchForm.multiCityDate_0),
    multiCityDate_1: useSelector((state) => state.searchForm.multiCityDate_1),
    multiCityDate_2: useSelector((state) => state.searchForm.multiCityDate_2),
    multiCityDate_3: useSelector((state) => state.searchForm.multiCityDate_3),
    multiCityDate_4: useSelector((state) => state.searchForm.multiCityDate_4)
  }
  
  const {
    multiCityFlyFrom_0,
    multiCityFlyTo_0,
    multiCityFlyFrom_1,
    multiCityFlyTo_1,
    multiCityFlyFrom_2,
    multiCityFlyTo_2,
    multiCityFlyFrom_3,
    multiCityFlyTo_3,
    multiCityFlyFrom_4,
    multiCityFlyTo_4,
    multiCityDate_0,
    multiCityDate_1,
    multiCityDate_2,
    multiCityDate_3,
    multiCityDate_4

  } = altEval
  

  const maxConnections = useSelector((state) => state.searchForm.maxConnections);
  const nearbyAirport = useSelector((state) => state.searchForm.nearbyAirport);
  const airContractObj = useSelector((state) => state.searchForm.airContract);
  const paxContract = useSelector((state) => state.searchForm.paxContract);
  const allianceStatus = useSelector((state) => state.searchForm.allianceOption);
  const airlines = useSelector((state) => state.searchForm.airlines);
  const flightType = useSelector((state) => state.searchForm.flightType);
  const lang = useSelector((state) => state.lang);
  const isInternalUserAutheticated = useSelector((state) => state.internalUser.isInternalUserAutheticated);
  const bookingChannel = isInternalUserAutheticated ? "internal" : "external";

  let allianceOption;
  let airlinesOptions;
  let passengersArray = [];
  let segmentsArray = [];
  let corrId = uuidv4();

  const handleNoSearch = () => {
    dispatch(setCityFraudCheck(false))
    setCityFraud(false);
    dispatch(setShowAgencyInfo(true));
    dispatch(setLoadingData(true))
  }

  async function handleYesSearch() {
    dispatch(setCityFraudCheck(false))
    dispatch(setLoadingData(false))
    setCityFraud(false)
    if (adultsCount) {
      const adults = {
        type: SearchWidgetInput.ADULT,
        quantity: adultsCount,
      };
      passengersArray.push(adults);
    }
    if (childCount) {
      const child = {
        type: SearchWidgetInput.CHILD,
        quantity: childCount,
      };
      passengersArray.push(child);
    }
    if (infantCount) {
      const infant = {
        type: SearchWidgetInput.INFANTINLAP,
        quantity: infantCount,
      };
      passengersArray.push(infant);
    }
    if (allianceStatus === allianceName.STAR_ALLIANCE) {
      allianceOption = starAllianceOption
    }
    if (allianceStatus === allianceName.ONE_WORLD) {
      allianceOption = oneWorldOption
    }
    if (allianceStatus === allianceName.SKY_TEAM) {
      allianceOption = skyTeam
    }
   

    airlinesOptions =
      airlines &&
      airlines.map((item, index) => {
        return item.value;
      });
    if (tripTypeValue === TripType.ROUNDTRIP) {
      const segementObj1 = {
        id: "1",
        departureLocation: nearbyAirport
          ? {
            code: flyingFrom,
            type: SearchWidgetInput.AIRPORT,
            alternateAirports: [],
          }
          : {
            code: flyingFrom,
            type: SearchWidgetInput.AIRPORT,
          },
        arrivalLocation: nearbyAirport
          ? {
            code: flyingTo,
            type: SearchWidgetInput.AIRPORT,
            alternateAirports: [],
          }
          : {
            code: flyingTo,
            type: SearchWidgetInput.AIRPORT,
          },

        fixedTravelDate: {
          departureDateTime: departureDate,
        },
        cabinType: cabin,
      };
      const segementObj2 = {
        id: "2",
        departureLocation: nearbyAirport
          ? {
            code: flyingTo,
            type: SearchWidgetInput.AIRPORT,
            alternateAirports: [],
          }
          : {
            code: flyingTo,
            type: SearchWidgetInput.AIRPORT,
          },
        arrivalLocation: nearbyAirport
          ? {
            code: flyingFrom,
            type: SearchWidgetInput.AIRPORT,
            alternateAirports: [],
          }
          : {
            code: flyingFrom,
            type: SearchWidgetInput.AIRPORT,
          },

        fixedTravelDate: {
          departureDateTime: returnDate,
        },

        cabinType: cabin,
      };

      segmentsArray.push(segementObj1, segementObj2);
    }

    if (tripTypeValue === TripType.ONEWAY) {
      const segment = {
        id: "1",
        departureLocation: nearbyAirport
          ? {
            code: flyingFrom,
            type: SearchWidgetInput.AIRPORT,
            alternateAirports: [],
          }
          : {
            code: flyingFrom,
            type: SearchWidgetInput.AIRPORT,
          },
        arrivalLocation: nearbyAirport
          ? {
            code: flyingTo,
            type: SearchWidgetInput.AIRPORT,
            alternateAirports: [],
          }
          : {
            code: flyingTo,
            type: SearchWidgetInput.AIRPORT,
          },

        fixedTravelDate: {
          departureDateTime: departureDate,
        },
        cabinType: cabin,
      };

      segmentsArray.push(segment);
    }

    if (tripTypeValue === TripType.OPENJAW) {
      const segementObj1 = {
        id: "1",
        departureLocation: nearbyAirport
          ? {
            code: flyingFrom,
            type: "Airport",
            alternateAirports: [],
          }
          : {
            code: flyingFrom,
            type: "Airport",
          },
        arrivalLocation: nearbyAirport
          ? {
            code: flyingTo,
            type: "Airport",
            alternateAirports: [],
          }
          : {
            code: flyingTo,
            type: "Airport",
          },

        fixedTravelDate: {
          departureDateTime: departureDate,
        },
        cabinType: cabin,
      };
      const segementObj2 = {
        id: "2",
        departureLocation: nearbyAirport
          ? {
            code: openJawFlyFrom_B,
            type: "Airport",
            alternateAirports: [],
          }
          : {
            code: openJawFlyFrom_B,
            type: "Airport",
          },
        arrivalLocation: nearbyAirport
          ? {
            code: openJawFlyTo_B,
            type: "Airport",
            alternateAirports: [],
          }
          : {
            code: openJawFlyTo_B,
            type: "Airport",
          },

        fixedTravelDate: {
          departureDateTime: openJawDate_B,
        },
        cabinType: cabin,
      };

      segmentsArray.push(segementObj1, segementObj2);
    }

    if (tripTypeValue === TripType.MULTICITY) {
      const segment1 = {
        id: "1",
        departureLocation: nearbyAirport
          ? {
            code: flyingFrom,
            type: SearchWidgetInput.AIRPORT,
            alternateAirports: [],
          }
          : {
            code: flyingFrom,
            type: SearchWidgetInput.AIRPORT,
          },
        arrivalLocation: nearbyAirport
          ? {
            code: flyingTo,
            type: SearchWidgetInput.AIRPORT,
            alternateAirports: [],
          }
          : {
            code: flyingTo,
            type: SearchWidgetInput.AIRPORT,
          },

        fixedTravelDate: {
          departureDateTime: departureDate,
        },
        cabinType: cabin,
      };

      segmentsArray.push(segment1);
    }


    let multicity_input = [];

    if (multiCityFlyFrom_0 !== "" && multiCityFlyTo_0 !== "" && multiCityDate_0 !== "") {
      multicity_input.push(0);
      dispatch(setIsErrorMultiCityTrip(false))
      dispatch(setModifySearchValue(false))
    }

    if (multiCityFlyFrom_1 !== "" && multiCityFlyTo_1 !== "" && multiCityDate_1 !== "") {
      multicity_input.push(1);
      dispatch(setIsErrorMultiCityTrip(false))
      dispatch(setModifySearchValue(false))
    }
    if (multiCityFlyFrom_2 !== "" && multiCityFlyTo_2 !== "" && multiCityDate_2 !== "") {
      multicity_input.push(2);
      dispatch(setIsErrorMultiCityTrip(false))
      dispatch(setModifySearchValue(false))
    }
    if (multiCityFlyFrom_3 !== "" && multiCityFlyTo_3 !== "" && multiCityDate_3 !== "") {
      multicity_input.push(3);
      dispatch(setIsErrorMultiCityTrip(false))
      dispatch(setModifySearchValue(false))
    }
    if (multiCityFlyFrom_4 !== "" && multiCityFlyTo_4 !== "" && multiCityDate_4 !== "") {
      multicity_input.push(4);
      dispatch(setIsErrorMultiCityTrip(false))
      dispatch(setModifySearchValue(false))
    }

    let idCount = 2
    multicity_input.map((number) => {

      const segment1 = {
        id: idCount + "",
        departureLocation: nearbyAirport
          ? {
            code: altEval["multiCityFlyFrom_" + number],
            type: SearchWidgetInput.AIRPORT,
            alternateAirports: [],
          }
          : {
            code: altEval["multiCityFlyFrom_" + number],
            type: SearchWidgetInput.AIRPORT,
          },
        arrivalLocation: nearbyAirport
          ? {
            code: altEval["multiCityFlyTo_" + number],
            type: SearchWidgetInput.AIRPORT,
            alternateAirports: [],
          }
          : {
            code: altEval["multiCityFlyTo_" + number],
            type: SearchWidgetInput.AIRPORT,
          },

        fixedTravelDate: {
          departureDateTime: altEval["multiCityDate_" + number],
        },
        cabinType: cabin,
      };

      segmentsArray.push(segment1);
      idCount++;
    })

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",

        correlationId: corrId,
        flightType:flightType
      },

      body: JSON.stringify({
        organizationId: orgId,
        agencyId: agencyId,
        agencyGroupId: agencyGroupId,
        userId: userId,
        agentId: agentId,
        agentName: agentName,
        agencyLocation: agencyLocation,
        triptype: tripTypeValue,
        segments: segmentsArray,
        passengers: passengersArray,
        brandedFares: {
          singleBrandedFare: true,
        },
        baggage: {
          freeBagRequired: atLeastFreeBaggage,
        },
        airlinesPreference: {
          preferred: (allianceStatus && allianceStatus !== "" && allianceStatus !== "Specific Airlines") ? allianceOption : airlinesOptions,
        },

        flightTypeOption: {
          maxStops: maxConnections,
        },
        contractTypes: airContractObj,
        paxTypes: paxContract,
        agencyName:agencyName
      })
    };
    dispatch(setTokenInitRequestData(requestOptions))

    // Axios payload 
    const config = {
      method: "POST",
      url: env.REACT_APP_API_BASE_URL + `/flight/search/getAllResultsV2`,
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",

        correlationId: corrId,
        flightType:flightType,
        bookingChannel:bookingChannel,
        channel: window.isVirtuoso() ? 'Virtuoso' : undefined
      },
      data:{
        organizationId: orgId,
        agencyId: agencyId,
        agencyGroupId: agencyGroupId,
        userId: userId,
        agentId: agentId,
        agentName: agentName,
        agencyLocation: agencyLocation,
        triptype: tripTypeValue,
        segments: segmentsArray,
        passengers: passengersArray,
        brandedFares: {
          singleBrandedFare: true,
        },
        baggage: {
          freeBagRequired: atLeastFreeBaggage,
        },
        airlinesPreference: {
          preferred: (allianceStatus && allianceStatus !== "" && allianceStatus !== "Specific Airlines") ? allianceOption : airlinesOptions,
        },

        flightTypeOption: {
          maxStops: maxConnections,
        },
        contractTypes: airContractObj,
        paxTypes: paxContract,
        agencyName:agencyName
      }
    }
    history.push("/SearchResults");
    
    if (tripTypeValue === TripType.ROUNDTRIP && flyingFrom !== "" && flyingTo !== "" && departureDate !== "" && returnDate !== "") {
        dispatch(setPNRRemarkCityFraudCheck(true))
        const response = await dispatch(getAllSearchResults(config))
        history.push("/SearchResults");
        if (response?.token) {
          const data = await response
          if (data.hasOwnProperty('token')) {
            //dispatch(setInitToken(data));
            dispatch(setPNRRemarkCityFraudCheck(true))
            //history.push("/SearchResults");
          }
          else {
            ToastMessages(t('106'));          
            history.push("/?user=internal")
          }
        }
      }

      else if (tripTypeValue === TripType.ONEWAY && flyingFrom !== "" && flyingTo !== "" && departureDate !== "") {
        // const response = await getApiResponse(config);
        dispatch(setPNRRemarkCityFraudCheck(true))
        const response = await dispatch(getAllSearchResults(config))
        history.push("/SearchResults");
        if (response?.token) {
          const data = await response
          if (data.hasOwnProperty('token')) {
            //dispatch(setInitToken(data));
            dispatch(setPNRRemarkCityFraudCheck(true))
            //history.push("/SearchResults");
          }
          else {
            ToastMessages(t('106'));           
            history.push("/?user=internal")
          }
        }
      }

      else if (tripTypeValue === TripType.OPENJAW && flyingFrom !== "" && flyingTo !== "" && openJawFlyFrom_B !== "" && openJawFlyTo_B !== "") {
        // const response = await getApiResponse(config);
        dispatch(setPNRRemarkCityFraudCheck(true))
        const response = await dispatch(getAllSearchResults(config))
        history.push("/SearchResults");
        if (response?.token) {
          const data = await response
          if (data.hasOwnProperty('token')) {
            //dispatch(setInitToken(data));
            dispatch(setPNRRemarkCityFraudCheck(true))
            //history.push("/SearchResults");
          }
          else {
            ToastMessages(t('106'));            
            history.push("/?user=internal")
          }
        }
      }

      else if (tripTypeValue === TripType.MULTICITY && flyingFrom !== "" && flyingTo !== "") {
        // const response = await getApiResponse(config);
        dispatch(setPNRRemarkCityFraudCheck(true))
        dispatch(setSegmentArrayLength(segmentsArray))
        const response = await dispatch(getAllSearchResults(config))
        history.push("/SearchResults");
        if (response?.token) {
          const data = await response
          if (data.hasOwnProperty('token')) {
            //dispatch(setInitToken(data));
            dispatch(setSegmentArrayLength(segmentsArray))
            dispatch(setPNRRemarkCityFraudCheck(true))
            //history.push("/SearchResults");
          }
          else {
            ToastMessages(t('106'));            
            history.push("/?user=internal")
          }
        }
      }
  }
  const handleCloseCityFraudCheckSearch = () => {
    dispatch(setCityFraudCheck(false))
    setCityFraud(false)
  }

  const handleCloseCityFraudSearch = () => {
    dispatch(setCityFraudCheck1(false))
    setCityFraud1(false)
  }

  const handleOkSearch = () => {
    dispatch(setCityFraudCheck1(false))
    setCityFraud1(false)
  }
  useEffect(() => {
      setCityFraud(isCityFraud)
  }, [isCityFraud])

  const [key, setKey] = useState(tripTypeValue && tripTypeValue.length > 0 ? tripTypeValue : "RoundTrip");

  const handleSelect = (k) => {
    setKey(k);
    dispatch(actionCreators.setTabName(k));
  }

  useEffect(() => {
    if (tabName) {
      setKey(tabName);;
    }
    dispatch(setQuotesTabType("Today"))
  }, [])

  useEffect(()=> {i18n.changeLanguage(lang.lang)},[lang])
  
  useEffect(()=>{
    if(tripTypeValue && tripTypeValue.length > 0){
      if (tripTypeValue === "Return") {
        setKey("RoundTrip")
      } else {
        setKey(tripTypeValue)
      }
    }
  },[tripTypeValue])
  
  // for language update redux
  const langChange = ({ target }) => {
    let { value } = target;
    dispatch({ type: "CHANGE LANGUAGE", data: value });
    i18n.changeLanguage(value);
  };

  
  return (
    <>   
      <Header setClass='container-fluid' show='d-none' />
      {/* <div style = {{ position:"absolute", right:"90px", top:"17px"}}>
                      <select
                        style = {{padding:" 2px 5px 2px 5px"}}
                        className="Language-Selector"
                        value={lang.lang || "en"}
                        onChange={langChange}
                        >
                        <option value="en">En</option>
                        <option value="fr">Fr</option>
                        </select>
                        </div> */}
      <BookingProvider>
      <div className="booking-main">
        <BookingFor />
        <div className="container postion-r bd-main-container white-space-manage" id="searchWidgetTab">
          <div className="sw-wrapper postion-r">
            <Tabs defaultActiveKey="RoundTrip" id="searchWidget" activeKey={key} onSelect={(k) => handleSelect(k)} className="ser_Wid_Tab" >

              <Tab eventKey="RoundTrip" title={t("RoundTrip")} id="round" className="tabs" >
                <Trip tripType={key} />
              </Tab>

              <Tab eventKey="OneWay" title={t("OneWay")} id="oneway" className="tabs">
                <Trip tripType={key} />
              </Tab>

              <Tab eventKey="OpenJaw" title={t("OpenJaw" )}id="openjaw" className="tabs" >
                <Trip tripType={key} />

              </Tab>

              <Tab eventKey="MultiCity" title={t("MultiCity")} id="multicity" className="tabs">
                <Trip tripType={key} />

              </Tab>
            </Tabs>
          </div>

        </div>
      </div>
      <Dialog
        open={cityFraud}
        keepMounted
        onClose={handleCloseCityFraudCheckSearch}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="bd-pi-contact-modal bd-alertBox">
          {t("Travel within 72 hours")}
          <button className="close" onClick={handleCloseCityFraudCheckSearch}>&times;</button>
          <div className="btn-wrapper">
            <button className="yes" onClick={handleYesSearch}>{t("Yes")}</button>
            <button className="no" onClick={handleNoSearch}>{t("No")}</button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={cityFraud1}
        keepMounted
        onClose={handleCloseCityFraudSearch}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="bd-pi-contact-modal bd-alertBox">
        {t("Travel within 72 hours isn’t")}
          <button className="close" onClick={handleCloseCityFraudSearch}>&times;</button>
          <div className="btn-wrapper">
            <button className="yes" onClick={handleOkSearch}>{t("OK")}</button>
          </div>
        </div>
      </Dialog>
      </BookingProvider>    
    </>
  );
}

export default SearchFormContainerNew;
